import { type AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Head from 'next/head';

import 'styles/index.scss';

// eslint-disable-next-line import/no-default-export
export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const isTemplateAuthor = pageProps?.data?.page_type === 'author';

  let asPath;

  if (typeof router.asPath === 'string') {
    const asPathWithoutQuery = router.asPath.split('?')[0];

    // * Apparently only 'author' template use query param - 'id'.
    if (isTemplateAuthor && router.query.id) {
      asPath = `${asPathWithoutQuery}?id=${router.query.id}`;
    }

    if (!isTemplateAuthor) {
      asPath = asPathWithoutQuery;
    }
  }

  return (
    <>
      <Head>{asPath ? <link rel="canonical" href={`${process.env.HOST}${asPath}`} /> : null}</Head>

      <Component {...pageProps} />
    </>
  );
}
